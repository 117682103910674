<template>
	<span>
		<mw-dialog large v-model="dialog" title="Import Colleagues">
			<template v-slot:button>
				<v-btn class="ml-2" color="primary" @click="dialog = true">
					Import Colleagues
				</v-btn>
			</template>
			<template v-slot:dialog-body>
				<v-stepper elevation="0" v-model="step">
					<v-stepper-header class="elevation-0">
						<v-stepper-step :complete="step > 1" step="1">
							Paste
						</v-stepper-step>
						<v-divider></v-divider>
						<v-stepper-step :complete="step > 2" step="2">
							Review
						</v-stepper-step>
						<v-divider></v-divider>
						<v-stepper-step :complete="step > 3" step="3">
							Import
						</v-stepper-step>
					</v-stepper-header>
					<v-stepper-content step="1">
						<v-card elevation="0">
							<p>
								You can add multiple team members in bulk via the field below.
								Please take to ensure that the format of your list is exactly as
								per the example shown. Once you have added your contacts, click
								Continue.
							</p>
							<p>
								<b>Note: you can include up to 30 colleagues per upload.</b>
							</p>
							<v-textarea
								v-model="paste"
								label="Paste comma delimited list of name and email pairs here"
							></v-textarea>
							<v-alert color="lightest" dense>
								Formatting example. <br />
								John Brown,john.brown@dma.works<br />
								Sarah Smith,sarah.smith@dma.works
							</v-alert>
							<v-card-actions class="mt-8 pb-0">
								<v-btn text @click="cancel">Cancel</v-btn>
								<v-spacer></v-spacer>
								<v-btn color="primary" @click="step = 2" :disabled="!paste">
									Continue
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>
					<v-stepper-content step="2">
						<v-card elevation="0">
							<p>
								Please check your contacts below. Contacts that contain errors
								have been excluded and contacts that already exist on the
								platform will not be imported.
							</p>
							<v-alert v-if="errors" color="secondary">
								Errors were found with {{ errors }} records.
							</v-alert>
							<v-simple-table>
								<thead>
									<tr>
										<th>Name</th>
										<th>Email</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, i) in items" :key="i">
										<td>
											<v-text-field v-model="item.name"></v-text-field>
										</td>
										<td>
											<v-text-field v-model="item.email"></v-text-field>
										</td>
									</tr>
								</tbody>
							</v-simple-table>
							<v-card-actions class="mt-8 pb-0">
								<v-btn text @click="cancel">Cancel</v-btn>
								<v-spacer></v-spacer>
								<v-btn color="primary" @click="step = 3">
									Continue
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>
					<v-stepper-content step="3">
						<v-card elevation="0">
							<p>
								The following records
								{{ started ? "are being" : "will be" }} imported:
							</p>
							<v-list dense>
								<v-list-item v-for="(item, i) in items" :key="i">
									<v-list-item-title>
										{{ item.name }}
									</v-list-item-title>
									<v-list-item-subtitle>
										{{ item.email }}
									</v-list-item-subtitle>
									<v-list-item-icon>
										<v-icon v-if="item.status == 1" color="success"
											>mdi-check-circle</v-icon
										>
									</v-list-item-icon>
								</v-list-item>
							</v-list>
							<v-card-actions class="mt-8 pb-0">
								<v-btn text @click="cancel">Cancel</v-btn>
								<v-spacer></v-spacer>
								<v-btn color="success" @click="submit" v-if="!started">
									Submit
								</v-btn>
								<v-btn color="primary" @click="cancel" v-if="finished">
									Close
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>
				</v-stepper>
			</template>
		</mw-dialog>
	</span>
</template>

<script>
	import _ from "lodash";
	import firebase from "firebase";
	import MwDialog from "@c/ui/MwDialog";
	export default {
		name: "ImportUsers",
		props: {},
		data: () => {
			return {
				step: 1,
				dialog: false,
				paste: null,
				items: [],
				errors: 0,
				finished: false,
				started: false
			};
		},
		components: {
			MwDialog
		},
		computed: {
			//
		},
		methods: {
			clear() {
				this.paste = null;
				this.step = 1;
				this.started = false;
				this.finished = false;
			},
			cancel() {
				this.clear();
				this.dialog = false;
			},
			stringToItems(str) {
				let arr = str.split("\n");
				arr = arr.filter((line) => line.length);
				arr = arr.filter((line) => line);
				arr = _.chunk(arr, 30);
				arr = arr[0];
				let items = arr.map((line) => {
					const values = line.split(",");
					return {email: values[1], name: values[0], status: 0};
				});
				items = items.filter((item) => item.email && item.name);
				items = items.filter((item) => {
					item.email = this.parseEmail(item.email);
					return (
						this.mwutils.validateEmail(item.email) && this.isName(item.name)
					);
				});

				this.items = items;
				this.errors = arr.length - items.length;
			},
			isName(value) {
				return value.length && value.split(" ").length > 1;
			},
			async addNewUser(name, email) {
				var functions = firebase.app().functions("europe-west1");
				var addUser = functions.httpsCallable("addColleague");
				var user = {
					organisation: this.$store.state.organisation.data.id,
					name,
					email
				};
				return await addUser(user);
			},
			parseEmail(a) {
				return a.toLowerCase().trim();
			},
			async submit() {
				const self = this;
				self.started = true;
				self.items.forEach(async (item) => {
					let res = await self.addNewUser(item.name, item.email);
					await self.$store.dispatch("users/fetchById", res.data.id);
					item.status = 1;
				});
				self.finished = true;
			}
		},
		watch: {
			paste(value) {
				this.stringToItems(value);
			}
		},
		created() {}
	};
</script>
